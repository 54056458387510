import { Link } from 'react-router-dom';

function NavButton({ title, target, verb='Browse' }) {
  return (
    <Link to={target} style={{lineHeight: 16 + 'px', fontSize: 13 + 'px'}}>
      {verb}
      <br />
      <b style={{fontSize: 15 + 'px'}}>{title}</b>
    </Link>
  )
}

export default NavButton;
