import MultiSelect from './MultiSelect.js';

function FilterSelect({
  title,
  options,
  selected,
  setSelected,
  formatter,
  height = '12rem',
  sort,
}) {
  return (
    <div>
      <div className="w-min flex flex-col gap-1">
        <div className="text-xs capitalize inline text-black font-bold">
          {title.toUpperCase()}
        </div>
        <div
          className="pt-1 pl-2 pr-5 rounded-sm bg-white mr-auto border-2 overflow-y-scroll w-full"
          style={{ minWidth: '100px', height: height }}
        >
          <MultiSelect
            options={[...new Set(options)]}
            selected={selected}
            setSelected={setSelected}
            formatter={formatter}
            title={title}
            sort={sort}
          />
        </div>
      </div>
    </div>
  );
}

export default FilterSelect;
