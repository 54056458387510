import Page from '../components/Page.js';
import BundleTable from '../components/BundleTable.js';
import config from '../config.js';

import axios from 'axios';
import { useState, useEffect } from 'react';

function Bundles() {
  const [bundles, setBundles] = useState([]);

  useEffect(() => {
    axios.get(config.urls.api + '/listing/bundles')
      .then(res => setBundles(res.data))
      .catch(() => alert('Uh oh, something went wrong!'));
  }, []);

  return (
    <Page title='Bundles'>
      <div className='2xl:w-5/6 mx-auto'>
        <div className='text-black mt-2 flex justify-between'>
          <div className='my-2 text-xl'>Bundles</div>
        </div>

        <div className='bg-white shadow-md p-5 text-black rounded-lg'>
          <div className='p-1 sm:p-5 flex gap-3'>
            <BundleTable bundles={bundles.filter((_, i) => i%2 === 1)} />
            <BundleTable bundles={bundles.filter((_, i) => i%2 === 0)} />
          </div>
        </div>
      </div>
    </Page>
  );
}

export default Bundles;
