import ContentLoader from 'react-content-loader';
import axios from 'axios';
import config from '../config.js'
import { useState, useEffect } from 'react';
import { stats } from 'util'
import { Link } from 'react-router-dom';

import PokemonCard from 'ui/PokemonCard.js'

function PokemonLoader({
  entry_id,
  setPokemon = undefined,
  parentData = null,
  href = '',
  scale = 1,
}) {
  const [data, setData] = useState(parentData);
  const [loading, setLoading] = useState(false);
  const source = axios.CancelToken.source();

  useEffect(() => {
    if (parentData === null) {
      if(loading) source.cancel();
      setLoading(true);
      axios.get(config.urls.api + '/listing/entry/' + entry_id, {cancelToken: source.token})
        .then(res => {
          setData(res.data);
          setLoading(false);
          if(setPokemon) setPokemon(res.data);
        })
        .catch(err => {
          if(axios.isCancel(err))
            console.debug(`request canceled for ${entry_id}`);
          else
            console.error(err)
        });
    }
    else {
      setLoading(parentData === undefined);
      setData(parentData);
    }
  }, [entry_id, parentData]);

  if(data && Object.keys(data).length) return (
    <Link className={'block w-min' + (href ? '' : ' pointer-events-none') } to={href ? href : ''}>
      <PokemonCard
        iframe_title={`entry#${entry_id}`}
        species={data.Species}
        level={data.Level}
        pokedex={data.Pokedex}
        ability={data.Ability}
        nature={data.Nature}
        gender={data.Gender}
        stats={stats(data.Base, data.Ev, data.Iv, data.Nature, data.Level)}
        moves={data.Moves}
        types={data.Type}
        item_name={data.ItemName}
        filename={data.Gif}
        shiny={data.Rarity > 0}
        giga={data.Factor === 2}
        scale={scale}
      />
    </Link>
    )
  else return (
    <ContentLoader
      style={{width: `${400 * scale}px`, height: `${240 * scale}px`}}
      viewBox={`0 0 ${400 * scale} ${240 * scale}`}
      backgroundColor="#5e92d4"
      backgroundOpacity={0.4}
      foregroundColor="#acd5ff"
      foregroundOpacity={0.4}
    >
      <rect x="0" y="0" rx="50" ry="50" width="100%" height="100%" />
    </ContentLoader>
  )
}

export default PokemonLoader;
