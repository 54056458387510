import Page from '../components/Page.js';
import abra from '../images/abra.png';
import config from '../config.js';

import { useEffect } from 'react';

function NotFound() {
  useEffect(() => {
    setTimeout(() => window.location.assign(config.urls.front), 2000);
  }, []);

  return (
    <Page title='✨'>
      <div className='flex flex-col p-20 place-items-center h-screen'>
        <img src={abra} width='15%' className='mx-auto' />
        <div className='text-center text-2xl text-black mt-4'>Oh no, Abra is using teleport!</div>
      </div>
    </Page>
  )
}

export default NotFound;
