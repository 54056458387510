import config from '../config.js';
import Page from '../components/Page.js';
import Table from '../components/Table.js';
import Section from '../components/Section.js';
import Field from '../components/Field.js';
import { entryToTitle, configurationToTitle, gameToLabel } from 'util';

import axios from 'axios';
import qs from 'qs';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';

function Orders({location}) {
  const {id: orderId, email} = qs.parse(location.search, {ignoreQueryPrefix: true});

  const [order, setOrder] = useState();
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if(orderId && email) {
      lookupOrder(orderId, email, setFetching, setOrder);
    }
  }, [email, orderId]);

  return (
    <Page title={orderId ? 'Order #' + orderId : 'Order Status Checker'}>
      <div className='min-h-min flex mx-auto w-min py-14'>
        {!fetching
        ? <Section className='w-min'>
          {order ? (
            <div>
              <div className='text-2xl'>Order Details</div>
              <hr className='my-3' />
              <Table rows={{
                'Order ID': order.Id,
                Fulfilled: order.Fulfilled ? 'Yes' : 'No',
                Compatibility: gameToLabel(order.Game),
                Email: order.Email,
                Phone: order.Phone || 'Not provided',
                Discord: order.Discord || 'Not provided',
                Pokemon: order.ListingEntries?.length ? order.ListingEntries.map((entry, i) => {
                  return (order.EntryQuantities[i] > 1 ? `${order.EntryQuantities}x `: '') + entryToTitle({...entry, ...entry.Pokemon}) + (order.EntryCustomizations[i] ? ` (${order.EntryCustomizations[i]})` : '')
                }).join(', ') : 'None',
                Items: order.Items?.length ? order.Items.map(item => item.Name).join(', ') : 'None',
                Bundles: order.BundleConfigurations?.length ? order.BundleConfigurations.map((configuration, i) => `${order.BundleConfigurationQuantities[i] > 1 ? `${order.BundleConfigurationQuantities[i]}x `: ''}${configurationToTitle(configuration)} ${configuration.BundleName}`).join(', ') : 'None',
              }} />
              { !order.Fulfilled && !order.Cancelled && (
                <div>
                  <div className='whitespace-nowrap my-3'>Looks like your order hasn't been fulfilled yet 😕</div>
                  <div>
                    <i>Be sure to:</i>
                    <ul className='list-decimal'>
                      <li><a className='text-pokezon underline' href='https://koalendar.com/e/set-up-a-delivery-time-with-froshtyspokezon' target='_blank' rel='noopener noreferrer'>Schedule an appointment</a> </li>
                      <li>Provide the <span className='text-feather font-bold'>Order ID {order.Id}</span> when asked for your order number</li>
                      <li>
                        <div className='whitespace-nowrap'>Reach out to Eevee by text/WhatsApp at +1(850) 345-4574</div>
                        <div className='w-min mx-auto text-pokezon'>OR</div>
                        <div className='whitespace-nowrap mx-auto w-min'>Discord at <span className='text-eevee'>eevee#6561</span></div>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              { order.Cancelled && (
                <div>
                  <div className='whitespace-nowrap my-3'>Looks like your order has been cancelled 😱</div>
                  <div>Check your email for more details and feel free to place another order once a resolution is reached.</div>
                </div>
              )}
              <button
                type='button'
                className='bg-pokezon rounded-md text-white py-2 block px-2 mx-auto mt-4 whitespace-nowrap'
                onClick={() => {
                  window.history.pushState({}, window.location.href, '?');
                  setOrder(undefined);
                }}
              >Back to Order Finder</button>
            </div>
          )
          : (
            <div>
              <div className='text-2xl'>Order Finder</div>
              <hr className='my-3' />
              <Formik
                initialValues={{id: '', email: ''}}
                onSubmit={values => {
                  console.debug(JSON.stringify(values, null, 4));
                  lookupOrder(values.id, values.email, setFetching, setOrder);
                }}
              >
                <Form>
                  <div className='flex flex-col gap-2'>
                    <Field name='id' label='Order ID' />
                    <Field name='email' label='Email' type='email' />
                    <button
                      className='bg-pokezon rounded-md text-white py-2 px-10 mt-4 whitespace-nowrap'
                      type='submit'
                    >
                      <span>
                        <FontAwesomeIcon className='m-auto opacity-0' icon={['fas', 'location-arrow']} />
                        <span className='mx-2'>Search</span>
                        <FontAwesomeIcon className='m-auto' icon={['fas', 'location-arrow']} />
                      </span>
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
                )}
        </Section>
        : <div>Loading...</div>}
      </div>
    </Page>
        )
      }

async function lookupOrder(orderId, email, setFetching, setOrder) {
  setFetching(true);
  const res = await axios.get(config.urls.api + '/payment/order/lookup', {params: {id: orderId, email}});
  setFetching(false);

  if(res.status === 200 || res.status === 304) {
    window.history.pushState({}, window.location.href, `?id=${orderId}&email=${encodeURIComponent(email)}`);
    setOrder(res.data);
  }
  else {
    alert('That email/ID combination didn\'t work, please double-check your input'); // TODO toast
  }
}

export default Orders;
