import { useMediaQuery } from 'react-responsive';

const Small = ({ children }) => {
  const isSmall = useMediaQuery({ maxWidth: 767 })
  return isSmall ? children : null
}

const NotSmall = ({ children }) => {
  const isNotSmall = useMediaQuery({ minWidth: 768 })
  return isNotSmall ? children : null
}

const Medium = ({ children }) => {
  const isMedium = useMediaQuery({ minWidth: 768, maxWidth: 1023 })
  return isMedium ? children : null
}

const Large = ({ children }) => {
  const isLarge = useMediaQuery({ minWidth: 1024 })
  return isLarge ? children : null
}

const NotLarge = ({ children }) => {
  const isNotLarge = useMediaQuery({ maxWidth: 1023 })
  return isNotLarge ? children : null
}

export {
  Large,
  NotLarge,
  Medium,
  Small,
  NotSmall,
}
