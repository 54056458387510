import BundleTableRow from './BundleTableRow.js';
import { bundleConfigurationRarity } from 'util';

function BundleTable({ bundles }) {
  const headerClass = 'p-2 font-bold w-min h-10';

  return (
    <table className="w-full">
      <thead className="text-left text-xs bg-gray-200">
        <th className={headerClass + ' w-96'}>BUNDLE</th>
        <th className={headerClass + ' w-20'}>SHINY</th>
        <th className={headerClass}>PRICE</th>
        <th className={headerClass} style={{ width: '70px' }} />
      </thead>
      <tbody>
        {bundles?.length ? (
          bundles.map(bundle =>
            bundle.Configurations.sort((a, b) => {
              // sort configurations from least to most rare within a bundle
              return (
                bundleConfigurationRarity(a) - bundleConfigurationRarity(b)
              );
            }).map(configuration => (
              <BundleTableRow bundle={bundle} configuration={configuration} />
            )),
          )
        ) : (
          <i className="block pt-2">Loading</i>
        )}
      </tbody>
    </table>
  );
}

export default BundleTable;
