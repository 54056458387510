import Page from '../components/Page.js';
import pokezon from '../images/pokezon.webp';

import { Link } from 'react-router-dom';

function UnderConstruction() {
  return (
    <Page title="Better together!">
      <div className="min-h-full flex flex-col place-content-between">
        <div className="h-auto flex flex-col place-items-center w-full lg:w-3/4 mx-auto p-10 sm:p-40 text-center">
          <img src={pokezon} className="absoulte mx-auto" width="500px" />
          <div className="text-3xl text-black mb-5">
            Froshty's Shop has joined Pokezon!
          </div>
          <div>
            <b>Froshty's Shop has joined forces with Pokezon</b>, which means
            that all of the same Pokemon that you could previously purchase from
            Froshty can now be found on Pokezon.
          </div>
          <div className="my-5">
            While Animal Crossing orders are no longer officially supported,
            Eevee can still be contacted for special arrangements. So, if you
            have any questions or specific requests, please don't hesitate to
            reach out on{' '}
            <a
              className="text-pokezon underline"
              href="https://discord.gg/VkNjF7WZPD"
            >
              the Discord server
            </a>
            .
          </div>
          <div>
            Enough chit-chat, let's{' '}
            <Link className="text-pokezon underline" to="/pokemon">
              build a team
            </Link>{' '}
            instead!
          </div>
        </div>
      </div>
    </Page>
  );
}

export default UnderConstruction;
