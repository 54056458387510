import config from 'config';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import { Small, NotSmall, Medium, Large } from '../responsive.js';

function Search({ className = '' }) {
  const placeholder = {
    large: 'Pokémon name, form, or pokedex number',
    medium: 'Search for Pokémon',
    small: 'Search',
  };

  return (
    <Formik
      initialValues={{ query: '' }}
      onSubmit={values => {
        axios
          .get(config.urls.api + '/listing/search', {
            params: { q: values.query },
          })
          .then(res => {
            if (res.data)
              window.location.assign(
                `${config.urls.front}/pokemon/${res.data.Id}?e=${res.data.EntryId}`,
              );
            else alert('Woops, no results found!'); // TODO toast
          });
      }}
    >
      <Form className={className}>
        <div>
          <Large>
            <Field
              id="search-query"
              className="border-b-2 border-feather outline-none h-8 min-w-full"
              type="text"
              name="query"
              placeholder={placeholder.large}
            />
            <label
              for="search-query"
              className="opacity-0 block cursor-text -mt-7 pr-5 whitespace-nowrap"
            >
              {placeholder.large}
            </label>
          </Large>

          <Medium>
            <Field
              id="search-query"
              className="border-b-2 border-feather outline-none h-8 w-44"
              type="text"
              name="query"
              placeholder={placeholder.medium}
            />
          </Medium>

          <Small>
            <Field
              id="search-query"
              className="border-b-2 border-feather outline-none h-8 w-max px-2 text-center"
              type="text"
              name="query"
              placeholder={placeholder.small}
            />
            <label
              for="search-query"
              className="opacity-0 block cursor-text -mt-7 whitespace-nowrap"
            >
              {placeholder.small}
            </label>
          </Small>
        </div>
      </Form>
    </Formik>
  );
}

export default Search;
