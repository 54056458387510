import Announcement from '../components/Announcement.js';
import config from '../config.js';
import discord from '../images/discord.png';
import PokemonLoader from '../components/PokemonLoader.js';

import axios from 'axios';
import { useState, useEffect } from 'react';

function Banner() {
  const [spotlight, setSpotlight] = useState();
  useEffect(() => {
    axios.get(config.urls.api + '/listing/spotlight').then(res => {
      setSpotlight(res.data);
    });
  }, []);

  return (
    <div className="w-screen">
      <div
        className="w-full lg:bg-night"
        style={{ marginBottom: '-760px', height: '760px' }}
      />

      <div className="mx-auto flex flex-wrap justify-between gap-5 bg-night pb-5 px-3 lg:bg-transparent lg:pb-0 h-auto">
        <div className="bg-white shadow-lg p-2 rounded-xl max-w-screen-xl mx-auto h-auto mt-5">
          <p>
            It is with a heavy heart that we announce the closing of
            Pokezon/Froshty this month.
          </p>

          <p>
            Sadly, all of us have other projects and responsibilities that are
            taking too much time to keep up with it all.
          </p>

          <p>
            I have been managing a Pokémon shop through Froshty/Pokezon for over
            10 years now. It first began as some fun side money while I was in
            college and grew into its own business over the years. I have met so
            many amazing people along the way both customers and staff, having
            up to 6 staff at our peak during COVID. We have delivered thousands
            of Pokemon starting from the release of Pokemon X/Y on 3DS to
            Scarlet and Violet on Switch.
          </p>

          <p>
            Thank you all so much for your support especially Eevee and Peter.
            It’s been a great part of my life and this is a closing of a
            chapter.{' '}
          </p>

          <p>
            <b>
              We will take orders through the end of this week (6/23) and our
              last operating day with be 6/28.
            </b>
          </p>
          <em>- Froshty and the Pokezon Team</em>
        </div>
      </div>
    </div>
  );
}

export default Banner;
