import { Field as FField, ErrorMessage } from 'formik';
function Field({name, label, type = 'text', className=''}) {
  return (
    <div className={className}>
      <div className='text-xs font-light'>{label}</div>
      <FField className='rounded-lg border-2 px-1' type={type} name={name} />
      <ErrorMessage component='div' className='text-pokezon font-mono text-xs' name={name} />
    </div>
  );
}

export default Field;
