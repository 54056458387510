import qs from 'qs';
import config from './config.js';
import { capitalize } from './util.js';

function PokemonCard({
  // required
  iframe_title,
  species,
  level,
  pokedex,
  ability,
  nature,
  gender,
  stats,
  types,
  moves,

  // optional
  scale = 1,
  filename,
  item_name,
  ball,
  shiny,
  square,
  giga,
  alpha,
  teratype,
}) {
  // handling partially filled arrays
  if (moves.length < 4) {
    Array(4 - moves.length)
      .fill('-----')
      .forEach((placeholder) => moves.push(placeholder));
  }
  if (types.length === 1) types.push('none');

  // defining final url
  const bool = (x) => (x ? 'on' : '');
  const query = qs.stringify({
    species,
    level,
    pokedex_number: pokedex,

    item: item_name,
    ability,
    nature: capitalize(nature),
    gender,
    hp: stats.hp || 0,
    attack: stats.attack || 0,
    defense: stats.defense || 0,
    sp_attack: stats.sp_attack || 0,
    sp_defense: stats.sp_defense || 0,
    speed: stats.speed || 0,
    move_1: moves[0],
    move_2: moves[1],
    move_3: moves[2],
    move_4: moves[3],
    type_1: types[0],
    type_2: types[1],
    shiny: bool(shiny),
    giga: bool(giga),
    alpha: bool(alpha),
    square: bool(square),
    teratype,

    filename: filename || '',
    item_name: item_name || '',
    ball: ball || 'pokéball',
  });

  const url = `${config.urls.gen}/pokemon?${query}`;

  // wrapping url into iframe
  return (
    <iframe
      className="pointer-events-none"
      title={iframe_title}
      style={{
        width: `${pokedex ? 400 : 500}px`,
        height: `${pokedex ? 240 : 260}px`,
        transform: `scale(${scale})`,
      }}
      src={url}
    />
  );
}

export default PokemonCard;
