import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Rarity({value}) {
  switch(value) {
    case 0: return <FontAwesomeIcon title='Regular' className='m-auto ml-5' icon={['fas', 'star']} color='#888' />
    case 1: return <FontAwesomeIcon title='Shiny' className='m-auto ml-5' icon={['fas', 'star']} color='red' />
    case 2: return (
      <div className='relative'>
        <FontAwesomeIcon title='Square Shiny' className='m-auto relative ml-5' icon={['fas', 'square']} color='#FFEE72' />
      </div>)
    default: return '';
  }
}

export default Rarity;
