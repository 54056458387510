import PokemonLoader from './PokemonLoader.js';
import Rarity from './Rarity.js';
import { capitalize, gameToLabel } from 'util';

import { useState } from 'react';

function PokemonRow({ id, href }) {
  const [pokemon, setPokemon] = useState();

  return (
    <tr className="border-b-2 border-gray-300">
      <td>
        <PokemonLoader entry_id={id} setPokemon={setPokemon} href={href} />
      </td>

      <td>
        {pokemon?.Type.filter(t => t !== 'none')
          .map(t => capitalize(t))
          .join(' / ')}
      </td>

      <td>
        <Rarity value={pokemon?.Rarity} />
      </td>

      <td>{gameToLabel(pokemon?.Game, true)}</td>

      <td>{pokemon?.Ability}</td>

      <td>{capitalize(pokemon?.Nature)}</td>

      <td>
        <div className="flex flex-col">
          {pokemon &&
            pokemon.Moves.filter(m => m[0] !== '-').map(m => <div>{m}</div>)}
        </div>
      </td>
    </tr>
  );
}

export default PokemonRow;
