const config = {};

switch (process.env.NODE_ENV) {
  default:
  case 'development':
    config.urls = {
      front: 'http://localhost:4000',
      api: 'http://localhost:1234',
      back: 'http://localhost:4224',
      gen: 'http://localhost:8000',
      rabbit: 'amqp://localhost:5672',
    };
    break;

  case 'production':
    config.urls = {
      front: 'https://pokezon.com',
      api: 'https://api.pokezon.com',
      back: 'https://back.pokezon.com',
      gen: 'https://gen.pokezon.com',
      rabbit: 'amqp://rabit.pokezon.com',
    };
    break;
}

config.rabbit = {
  tradeExchange: {
    name: 'trades',
    get args(){return [this.name, 'direct', {durable: false}]},
  }
};

export default config;
