function StatGraph({stats, percents}) {
  return (
    <table>
      <tbody>
        {Object.entries(stats).map(([k, v]) => row(k, v, percents[k]))}
      </tbody>
    </table>
  );
}

function row(label, stat, percent) {
  return (
    <tr>
      <td className='py-1 pr-2 text-sm'>{{
        hp: 'HP',
        attack: 'Attack',
        defense: 'Defense',
        sp_attack: 'Sp. Atk',
        sp_defense: 'Sp. Def',
        speed: 'Speed',
      }[label]}:
      </td>
      <td className='w-full'>
        <div className='overflow-hidden' style={{
          background: 'linear-gradient(to bottom, #EEE, #F6F6F6) repeat scroll 0% 0% transparent',
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08) inset, 0px -1px 0px rgba(0, 0, 0, 0.03) inset',
          borderRadius: '1px',
          height: '18px',
        }}>
          <div style={{
            width: `${percent}%`,
            borderRadius: '1px',
            float: 'left',
            height: '100%',
            backgroundImage: 'linear-gradient(#66bdff, #0d9eff)',
            transition: 'width 0.5s ease 0s',
          }} />
        </div>
      </td>
      <td className='text-sapphire text-sm px-1'>
        {stat}
      </td>
      <td>
        {/* TODO 31 IV badge */}
      </td>
    </tr>
  );
}

export default StatGraph;
