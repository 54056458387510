import Page from '../components/Page.js';
import ListingTable from '../components/ListingTable.js';
import config from '../config.js';
import { scrollToTag } from 'util';

import axios from 'axios';
import qs from 'qs';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ROWS_PER_PAGE = 5;

function Pokemon({ location }) {
  const [entries, setEntries] = useState();

  const pageCandidate = Math.max(
    qs.parse(location.search, { ignoreQueryPrefix: true }).p,
    1,
  );
  const [page, setPage] = useState(
    Number.isInteger(pageCandidate) ? pageCandidate : 1,
  );
  const [total, setTotal] = useState();
  const [params, setParams] = useState({});
  const [debouncer, setDebouncer] = useState();
  const [goto, setGoto] = useState('');

  useEffect(() => {
    if (debouncer) clearTimeout(debouncer);

    setDebouncer(
      setTimeout(() => {
        axios
          .get(config.urls.api + '/listing/entries', {
            params: {
              offset: page > 1 ? (page - 1) * ROWS_PER_PAGE : undefined,
              limit: ROWS_PER_PAGE,
              ...params,
            },
          })
          .then(res => {
            setEntries(res.data.data);
            setTotal(res.data.total);
            if (page > Math.ceil(res.data.total / ROWS_PER_PAGE))
              setPage(Math.max(Math.ceil(res.data.total / ROWS_PER_PAGE), 1));
          });
      }, 500),
    );
  }, [params, page]);

  useEffect(
    () => window.history.pushState({}, window.location.href, '?p=' + page),
    [page],
  );

  return (
    <Page title="Pokemon">
      <div className="p-1 sm:p-5">
        {entries ? (
          <ListingTable entries={entries} total={total} setParams={setParams} />
        ) : (
          <div>Fetching the latest Pokemon...</div>
        )}

        <div className="flex gap-5 w-32 mx-auto justify-between">
          <button
            onClick={() => {
              scrollToTag('th');
              setPage(Math.max(page - 1, 1));
            }}
          >
            <FontAwesomeIcon className="m-auto" icon={['fas', 'arrow-left']} />
          </button>

          <div className="flex flex-col gap-1 place-items-center">
            <div className="whitespace-nowrap">
              {page - (total === 0 ? 1 : 0)} /{' '}
              {!Number.isNaN(Math.ceil(total / ROWS_PER_PAGE))
                ? Math.ceil(total / ROWS_PER_PAGE)
                : 1}
            </div>
            <input
              className="w-28 text-center"
              placeholder="Go to page..."
              value={goto}
              onKeyPress={e => {
                if (e.charCode == 13) {
                  if (
                    Number(goto) >= 1 &&
                    Number(goto) <= Math.ceil(total / ROWS_PER_PAGE)
                  )
                    setPage(Number(goto));

                  setGoto('');
                  scrollToTag('th');
                }
              }}
              onChange={e => setGoto(e.target.value)}
            />
          </div>

          <button
            onClick={() => {
              scrollToTag('th');
              setPage(Math.min(page + 1, Math.ceil(total / ROWS_PER_PAGE)));
            }}
          >
            <FontAwesomeIcon className="m-auto" icon={['fas', 'arrow-right']} />
          </button>
        </div>
      </div>
    </Page>
  );
}

export default Pokemon;
