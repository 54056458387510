import Page from '../components/Page.js';
import Compatibility from '../components/Compatibility.js';
import ItemCard from '../components/ItemCard.js';
import config from '../config.js';
import { gameToLabel, without } from 'util';
import { NotSmall } from '../responsive.js';
import constants from '../constants.js';

import axios from 'axios';
import { useState, useEffect } from 'react';

function Items() {
  const [items, setItems] = useState();
  const [currentItem, setCurrentItem] = useState();
  const [currentGame, setCurrentGame] = useState('bdsp');

  useEffect(() => {
    axios.get(config.urls.api + '/listing/items').then(res => {
      setItems(res.data.items);
      setCurrentItem(res.data.items.find(item => item.Name === 'Master Ball'));
    });
  }, []);

  return (
    <Page title="Items">
      <div className="w-5/6 mx-auto max-h-screen p-5 text-gray-800">
        <div
          className="grid gap-5 max-h-screen"
          style={{ gridTemplateColumns: '0.7fr 0.3fr' }}
        >
          <div className="bg-white shadow-md p-5 rounded-lg">
            <div>
              <div className="flex justify-between">
                <div>
                  <div className="text-2xl">
                    {currentItem?.Name || 'Loading...'}
                  </div>
                  <div className="text-sm">
                    <span className="text-gray-600">Compatible with: </span>
                    <span>{gameToLabel(currentGame)}</span>
                  </div>
                </div>
                <NotSmall>
                  <Compatibility game={currentGame} />
                </NotSmall>
              </div>
            </div>
            <hr className="my-4" />
            <div
              className="grid gap-3"
              style={{ gridTemplateColumns: '0.3fr 700px' }}
            >
              {currentItem ? (
                <ItemCard image={currentItem.Image} name={currentItem.Name} />
              ) : (
                <div>Loading...</div>
              )}
              <div>
                <div>
                  Game:{' '}
                  <span className="inline-flex gap-1">
                    {without('la', constants.GAMES).map(game => (
                      <button
                        className={`px-1 border-2 rounded-md ${
                          game === currentGame
                            ? 'border-pokezon'
                            : 'border-gray-400'
                        }`}
                        type="button"
                        onClick={() => {
                          setCurrentGame(game);

                          // if current item doesn't exist in new game, revert to master ball
                          if (!currentItem.Games.includes(game))
                            setCurrentItem(
                              items.find(item => item.Name === 'Master Ball'),
                            );
                        }}
                      >
                        {gameToLabel(game, true)}
                      </button>
                    ))}
                  </span>
                </div>
                <div>Item:</div>
                {items && currentItem ? (
                  <div
                    className="grid grid-cols-4 gap-1 min-w-full overflow-y-auto overflow-x-hidden pr-2"
                    style={{ height: '580px' }}
                  >
                    {items
                      .filter(item => item.Games.includes(currentGame))
                      .map(item => (
                        <button
                          className={`px-1 border-2 whitespace-nowrap text-sm rounded-md text-left ${
                            item.Id === currentItem.Id
                              ? 'border-pokezon'
                              : 'border-gray-400'
                          }`}
                          style={{ letterSpacing: '-0.04em' }}
                          type="button"
                          onClick={() => setCurrentItem(item)}
                        >
                          {item.Name}
                        </button>
                      ))}
                  </div>
                ) : (
                  <div>Loading...</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default Items;
