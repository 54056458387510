function Table({rows}) {
  return (
    <table className='table-fixed whitespace-nowrap w-full md:w-auto'>
      <tbody>
        {rows && Object.entries(rows).map(([key, value]) => (
          <tr className='text-sm' style={{border: '1pt dotted #ccc', borderRight: '0'}}>
            <td className='text-gray-500 bg-gray-100 p-1 w-32'>{key}:</td>
            <td className='pl-1 whitespace-pre-wrap'>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Table;
