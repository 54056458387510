let config = {
  urls: {
    api: undefined,
    gen: undefined,
  },
  stripe: {
    publishable: undefined,
    products: {
      pokemon: undefined,
      item: undefined,
      bundle: undefined,
    },
  }
};

switch(process.env.NODE_ENV) {
  default:
  case 'development':
    config.urls = {
      api: 'http://192.168.0.27:1234',
      gen: 'http://192.168.0.27:8000',
      front: 'http://192.168.0.27:3000',
    };

    config.stripe = {
      publishable: 'pk_test_51Jls6oJoU1SgHzKOuJfNs3IVMQssduh0tIGUzcYBiVUi8RfLIrHqn0oI0P44KzF1RAVQRlb4DaqHz8SzkKfafva4003kAMTm2L',
    };

    break;

  case 'production':
    config.urls = {
      api: 'https://api.pokezon.com',
      gen: 'https://gen.pokezon.com',
      front: 'https://pokezon.com',
    }

    config.stripe = {
      publishable: 'pk_live_51Jls6oJoU1SgHzKOo4vw3HE2g1qbqcZPAYJ9rQtiQQu9j53WqTx96WvpdrkgNYRX6m9GsQJTW5rN48R8usJFKmkc00azcgtwFj',
    };
    break;
}

export default config;
