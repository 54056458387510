import { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config.js'
import { pokedexToMiniURL } from 'util'

function Announcement() {
  const [message, setMessage] = useState();
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if(!fetched) {
      axios.get(config.urls.api + '/announcement')
        .then(res => {
          setMessage(res.data);
          setFetched(true);
        });
    }
  });

  return (
    <div className='p-4 text-center text-eevee font-extrabold flex justify-center items-center min-w-min text-xl lg:text-3xl' style={{zIndex: 5}}>
      <div className='whitespace-nowrap'>
          {message || ''}
      </div>
      <img className='mb-1' src={pokedexToMiniURL(133)} />
    </div>
  )
}

export default Announcement;
