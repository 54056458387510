import PokemonRow from './PokemonRow.js';
import FilterSelect from '../components/FilterSelect.js';
import Button from '../components/Button.js';
import constants from '../constants.js';
import { gameToLabel } from 'util';

import { useState, useEffect } from 'react';

function ListingTable({ entries, total, setParams }) {
  const [types, setTypes] = useState([]);
  const [games, setGames] = useState([]);
  const [natures, setNatures] = useState([]);
  const [abilities, setAbilities] = useState([]);
  const [levels, setLevels] = useState([]);
  const [rarities, setRarities] = useState([]);
  const [factors, setFactors] = useState([]);
  const [genders, setGenders] = useState([]);
  const [moves, setMoves] = useState([]);
  const [tiers, setTiers] = useState([]);
  const [builds, setBuilds] = useState([]);

  useEffect(() => {
    setParams({
      types,
      games,
      natures,
      abilities,
      levels,
      rarities,
      factors,
      genders,
      moves,
      tiers,
      builds,
    });
  }, [
    types,
    games,
    natures,
    abilities,
    levels,
    rarities,
    factors,
    genders,
    moves,
    tiers,
    builds,
    setParams,
  ]);

  const headerClass = 'p-2 font-bold w-min';
  return (
    <div className="2xl:w-5/6 mx-auto overflow-scroll">
      <div className="flex gap-3"></div>
      <div className="px-2 pt-1 flex flex-wrap gap-3 mx-auto justify-start">
        <div className="flex flex-col justify-between">
          <FilterSelect
            title="types"
            options={constants.TYPES}
            selected={types}
            setSelected={setTypes}
            sort
          />
        </div>

        <FilterSelect
          title="moves"
          options={constants.MOVES}
          selected={moves}
          setSelected={setMoves}
        />

        <FilterSelect
          height="min"
          title="games"
          options={constants.GAMES}
          formatter={g => gameToLabel(g, true)}
          selected={games}
          setSelected={setGames}
        />

        <div className="flex flex-col justify-between">
          <FilterSelect
            height="min"
            title="level"
            options={['Starters', 'Fully Trained']}
            selected={levels}
            setSelected={setLevels}
          />
          <FilterSelect
            height="min"
            title="genders"
            options={constants.GENDERS}
            selected={genders}
            setSelected={setGenders}
          />
        </div>

        <FilterSelect
          title="natures"
          options={constants.NATURES}
          selected={natures}
          setSelected={setNatures}
          sort
        />

        <FilterSelect
          title="builds"
          options={constants.BUILDS}
          selected={builds}
          setSelected={setBuilds}
          formatter={s => s}
        />

        <FilterSelect
          title="abilities"
          options={constants.ABILITIES}
          selected={abilities}
          setSelected={setAbilities}
          sort
        />
        <FilterSelect
          height="min"
          title="rarity"
          options={constants.RARITIES}
          formatter={r =>
            ({ regular: 'Regular', shiny: 'Shiny', square: 'Square Shiny' }[r])
          }
          selected={rarities}
          setSelected={setRarities}
        />
        <FilterSelect
          height="min"
          title="factors"
          options={constants.FACTORS}
          selected={factors}
          setSelected={setFactors}
        />
        <FilterSelect
          height="min"
          title="tiers"
          options={constants.TIERS}
          selected={tiers}
          setSelected={setTiers}
          sort
          formatter={s => s}
        />
      </div>

      <Button
        text="Reset Filters"
        onClick={() => {
          setTypes([]);
          setGames([]);
          setNatures([]);
          setAbilities([]);
          setLevels([]);
          setRarities([]);
          setFactors([]);
          setGenders([]);
          setMoves([]);
          setTiers([]);
          setBuilds([]);
        }}
      />

      <div className="text-black mt-2 flex justify-between">
        <div className="my-2 text-xl">{total ?? 0} Filtered Pokemon</div>
      </div>

      <div className="bg-white shadow-md p-5 text-black rounded-lg overflow-scroll">
        <table className="w-full min-w-max overflow-scroll">
          <thead className="text-left text-xs bg-gray-200">
            <th className={headerClass} style={{ width: '430px' }}>
              POKEMON
            </th>
            <th className={headerClass}>TYPE</th>
            <th className={headerClass} style={{ width: '70px' }}>
              SHINY
            </th>
            <th className={headerClass}>COMPATIBILITY</th>
            <th className={headerClass}>ABILITY</th>
            <th className={headerClass}>NATURE</th>
            <th className={headerClass}>MOVES</th>
          </thead>
          <tbody className="overflow-scroll">
            {total === 0 && (
              <i className="block pt-2">
                No Pokemon match the selected filters
              </i>
            )}
            {entries.map(entry => (
              <PokemonRow
                id={entry.Id}
                href={`/pokemon/${entry.ListingId || 0}?e=${entry.Id}`}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ListingTable;
