import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Footer() {
  return (
    <div className="bg-white h-full" style={{ borderTop: '5px solid #ec2028' }}>
      <div className="px-5 mx-auto text-black text-sm flex flex-col">
        <div className="flex justify-between px-2 gap-3 mt-5">
          <div className="ml-auto">
            <div className="uppercase font-semibold">my account</div>
            <div className="flex flex-col gap-1">
              <Link to="/orders">Orders</Link>
            </div>
          </div>

          <div>
            <div className="uppercase font-semibold">support</div>
            <div className="flex flex-col gap-1">
              <Link to="/faq">FAQ</Link>
              <Link to="/faq#return">Return Policy</Link>
              <Link to="/tos">Terms of Service</Link>
            </div>
          </div>

          <div className="mr-auto">
            <div className="uppercase font-semibold">contact us</div>
            <div
              className="grid justify-center"
              style={{ gridTemplateColumns: '20px auto' }}
            >
              <FontAwesomeIcon
                className="m-auto"
                icon={['fas', 'mobile-alt']}
              />
              <span className="ml-1 whitespace-nowrap">+1(850) 345-4574</span>

              <FontAwesomeIcon className="m-auto" icon={['fab', 'discord']} />
              <span className="ml-1">eevee#6561</span>

              <FontAwesomeIcon className="m-auto" icon={['fas', 'envelope']} />
              <a href="mailto:support@pokezon.com" className="underline ml-1">
                support@pokezon.com
              </a>
            </div>
          </div>
        </div>

        <div className="text-center text-sm font-light border-t-2 mt-4">
          This site is not associated with Nintendo Co., Ltd. All trademarks,
          logos and copyrights are property of their respective owners. Pokezon
          only provides a digital service and does not sell physical goods.
        </div>
      </div>
    </div>
  );
}

export default Footer;
