import Header from './Header.js';
import Footer from './Footer.js';

import { useEffect } from 'react';

function Page({ children, title }) {
  useEffect(() => {
    document.title = (title ? title + ' - ' : '') + 'Pokezon';
  }, [title]);

  return (
    <>
      <head>
        <title>{(title ? title + ' - ' : '') + 'Pokezon'}</title>
        <meta httpEquiv="X-UA-Compatible" content="chrome=1" />
        <meta charSet="utf-8" />
        <meta
          name="keywords"
          content="pokemon, gaming, nintendo, nintendo switch"
        />
        <meta name="language" content="english" />
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="Pokezon was a reliable, fast, and safe way to get any Pokemon you want whether it be a shiny 6IV starter or a mythical Pokemon from a past event. Our prices were low so that you felt like you'd saved time but not wasted money."
        />
        <meta httpEquiv="cache-control" content="no-cache" />
        <meta httpEquiv="expires" content="43200" />
      </head>
      <body>
        <div className="text-gray-600 bg-beluga flex flex-col overflow-scroll">
          <Header />
          <div>{children}</div>
          <Footer />
        </div>
      </body>
    </>
  );
}

export default Page;
