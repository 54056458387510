import * as pages from './pages';

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

// initialize fontawesome icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fab, fas);

// define routes
function App() {
  return (
    <Router>
      <Switch>
        <Route path="/about">
          woa neat
          <br />
          <Link to="/">Go back</Link>
        </Route>

        <Route exact path="/pokemon/:listing_id" component={pages.Listing} />
        <Route exact path="/pokemon" component={pages.Pokemon} />
        <Route exact path="/items" component={pages.Items} />
        <Route exact path="/bundles" component={pages.Bundles} />
        <Route exact path="/bundles/:bundle_id" component={pages.Bundle} />
        <Route exact path="/faq" component={pages.UnderConstruction} />
        <Route exact path="/tos" component={pages.ToS} />
        <Route exact path="/orders" component={pages.Orders} />
        <Route exact path="/froshty" component={pages.Froshty} />
        <Route exact path="/" component={pages.Home} />
        <Route component={pages.NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
