const constants = {
  GENERATIONS: [4, 6, 7, 8],
  RARITIES: ['regular', 'shiny', 'square'],
  FACTORS: ['regular', 'dynamax', 'gigantamax'],
  GENDERS: ['male', 'female', 'genderless'],
  NATURES: [
    'hardy',
    'docile',
    'serious',
    'bashful',
    'quirky',
    'lonely',
    'brave',
    'adamant',
    'naughty',
    'bold',
    'relaxed',
    'impish',
    'lax',
    'timid',
    'hasty',
    'jolly',
    'naive',
    'modest',
    'mild',
    'quiet',
    'rash',
    'calm',
    'gentle',
    'sassy',
    'careful',
  ],
  ABILITIES: [
    'Adaptability',
    'Aerilate',
    'Aftermath',
    'Air Lock',
    'Analytic',
    'Anger Point',
    'Anticipation',
    'Arena Trap',
    'Aroma Veil',
    'As One',
    'Aura Break',
    'Bad Dreams',
    'Ball Fetch',
    'Battery',
    'Battle Armor',
    'Battle Bond',
    'Beast Boost',
    'Berserk',
    'Big Pecks',
    'Blaze',
    'Bulletproof',
    'Cheek Pouch',
    'Chilling Neigh',
    'Chlorophyll',
    'Clear Body',
    'Cloud Nine',
    'Color Change',
    'Comatose',
    'Competitive',
    'Compound Eyes',
    'Contrary',
    'Corrosion',
    'Cotton Down',
    'Curious Medicine',
    'Cursed Body',
    'Cute Charm',
    'Damp',
    'Dancer',
    'Dark Aura',
    'Dauntless Shield',
    'Dazzling',
    'Defeatist',
    'Defiant',
    'Delta Stream',
    'Desolate Land',
    'Disguise',
    'Download',
    "Dragon's Maw",
    'Drizzle',
    'Drought',
    'Dry Skin',
    'Early Bird',
    'Effect Spore',
    'Electric Surge',
    'Emergency Exit',
    'Fairy Aura',
    'Filter',
    'Flame Body',
    'Flare Boost',
    'Flash Fire',
    'Flower Gift',
    'Flower Veil',
    'Fluffy',
    'Forecast',
    'Forewarn',
    'Friend Guard',
    'Frisk',
    'Full Metal Body',
    'Fur Coat',
    'Gale Wings',
    'Galvanize',
    'Gluttony',
    'Gooey',
    'Gorilla Tactics',
    'Grass Pelt',
    'Grassy Surge',
    'Grim Neigh',
    'Gulp Missile',
    'Guts',
    'Harvest',
    'Healer',
    'Heatproof',
    'Heavy Metal',
    'Honey Gather',
    'Huge Power',
    'Hunger Switch',
    'Hustle',
    'Hydration',
    'Hyper Cutter',
    'Ice Body',
    'Ice Face',
    'Ice Scales',
    'Illuminate',
    'Illusion',
    'Immunity',
    'Imposter',
    'Infiltrator',
    'Innards Out',
    'Inner Focus',
    'Insomnia',
    'Intimidate',
    'Intrepid Sword',
    'Iron Barbs',
    'Iron Fist',
    'Justified',
    'Keen Eye',
    'Klutz',
    'Leaf Guard',
    'Levitate',
    'Libero',
    'Light Metal',
    'Lightning Rod',
    'Limber',
    'Liquid Ooze',
    'Liquid Voice',
    'Long Reach',
    'Magic Bounce',
    'Magic Guard',
    'Magician',
    'Magma Armor',
    'Magnet Pull',
    'Marvel Scale',
    'Mega Launcher',
    'Merciless',
    'Mimicry',
    'Minus',
    'Mirror Armor',
    'Misty Surge',
    'Mold Breaker',
    'Moody',
    'Motor Drive',
    'Moxie',
    'Multiscale',
    'Multitype',
    'Mummy',
    'Natural Cure',
    'Neuroforce',
    'Neutralizing Gas',
    'No Guard',
    'Normalize',
    'Oblivious',
    'Overcoat',
    'Overgrow',
    'Own Tempo',
    'Parental Bond',
    'Pastel Veil',
    'Perish Body',
    'Pickpocket',
    'Pickup',
    'Pixilate',
    'Plus',
    'Poison Heal',
    'Poison Point',
    'Poison Touch',
    'Power Construct',
    'Power of Alchemy',
    'Power Spot',
    'Prankster',
    'Pressure',
    'Primordial Sea',
    'Prism Armor',
    'Propeller Tail',
    'Protean',
    'Psychic Surge',
    'Punk Rock',
    'Pure Power',
    'Queenly Majesty',
    'Quick Draw',
    'Quick Feet',
    'Rain Dish',
    'Rattled',
    'Receiver',
    'Reckless',
    'Refrigerate',
    'Regenerator',
    'Ripen',
    'Rivalry',
    'RKS System',
    'Rock Head',
    'Rough Skin',
    'Run Away',
    'Sand Force',
    'Sand Rush',
    'Sand Spit',
    'Sand Stream',
    'Sand Veil',
    'Sap Sipper',
    'Schooling',
    'Scrappy',
    'Screen Cleaner',
    'Serene Grace',
    'Shadow Shield',
    'Shadow Tag',
    'Shed Skin',
    'Sheer Force',
    'Shell Armor',
    'Shield Dust',
    'Shields Down',
    'Simple',
    'Skill Link',
    'Slow Start',
    'Slush Rush',
    'Sniper',
    'Snow Cloak',
    'Snow Warning',
    'Solar Power',
    'Solid Rock',
    'Soul-Heart',
    'Soundproof',
    'Speed Boost',
    'Stakeout',
    'Stall',
    'Stalwart',
    'Stamina',
    'Stance Change',
    'Static',
    'Steadfast',
    'Steam Engine',
    'Steelworker',
    'Steely Spirit',
    'Stench',
    'Sticky Hold',
    'Storm Drain',
    'Strong Jaw',
    'Sturdy',
    'Suction Cups',
    'Super Luck',
    'Surge Surfer',
    'Swarm',
    'Sweet Veil',
    'Swift Swim',
    'Symbiosis',
    'Synchronize',
    'Tangled Feet',
    'Tangling Hair',
    'Technician',
    'Telepathy',
    'Teravolt',
    'Thick Fat',
    'Tinted Lens',
    'Torrent',
    'Tough Claws',
    'Toxic Boost',
    'Trace',
    'Transistor',
    'Triage',
    'Truant',
    'Turboblaze',
    'Unaware',
    'Unburden',
    'Unnerve',
    'Unseen Fist',
    'Victory Star',
    'Vital Spirit',
    'Volt Absorb',
    'Wandering Spirit',
    'Water Absorb',
    'Water Bubble',
    'Water Compaction',
    'Water Veil',
    'Weak Armor',
    'White Smoke',
    'Wimp Out',
    'Wonder Guard',
    'Wonder Skin',
    'Zen Mode',
  ],
  TYPES: [
    'unnest',
    'fighting',
    'dark',
    'rock',
    'psychic',
    'fire',
    'water',
    'steel',
    'dragon',
    'electric',
    'ice',
    'bug',
    'poison',
    'ground',
    'flying',
    'fairy',
    'ghost',
    'normal',
    'grass',
  ],
  MOVES: [
    'Absorb',
    'Accelerock',
    'Acid Armor',
    'Acid Spray',
    'Acrobatics',
    'Acupressure',
    'Aerial Ace',
    'Aeroblast',
    'After You',
    'Agility',
    'Air Slash',
    'Ally Switch',
    'Anchor Shot',
    'Ancient Power',
    'Apple Acid',
    'Aqua Jet',
    'Aqua Ring',
    'Aqua Tail',
    'Aromatherapy',
    'Aromatic Mist',
    'Assurance',
    'Astonish',
    'Attract',
    'Aura Sphere',
    'Aura Wheel',
    'Aurora Veil',
    'Autotomize',
    'Avalanche',
    'Baby-Doll Eyes',
    'Baneful Bunker',
    'Baton Pass',
    'Belch',
    'Belly Drum',
    'Bind',
    'Bite',
    'Blast Burn',
    'Blaze Kick',
    'Blizzard',
    'Blue Flare',
    'Body Press',
    'Body Slam',
    'Bolt Beak',
    'Bolt Strike',
    'Bonemerang',
    'Boomburst',
    'Bounce',
    'Brave Bird',
    'Brick Break',
    'Brutal Swing',
    'Bubble Beam',
    'Bug Buzz',
    'Bulk Up',
    'Bullet Punch',
    'Bullet Seed',
    'Burn Up',
    'Burning Jealousy',
    'Calm Mind',
    'Charm',
    'Circle Throw',
    'Clear Smog',
    'Close Combat',
    'Coil',
    'Confide',
    'Confuse Ray',
    'Confusion',
    'Conversion',
    'Copycat',
    'Cosmic Power',
    'Cotton Guard',
    'Counter',
    'Court Change',
    'Crabhammer',
    'Cross Chop',
    'Cross Poison',
    'Crunch',
    'Crush Claw',
    'Curse',
    'Dark Pulse',
    'Darkest Lariat',
    'Dazzling Gleam',
    'Defense Curl',
    'Defog',
    'Destiny Bond',
    'Detect',
    'Diamond Storm',
    'Dig',
    'Disable',
    'Disarming Voice',
    'Discharge',
    'Double Hit',
    'Double Iron Bash',
    'Double Kick',
    'Double Team',
    'Double-Edge',
    'Draco Meteor',
    'Dragon Ascent',
    'Dragon Breath',
    'Dragon Claw',
    'Dragon Dance',
    'Dragon Pulse',
    'Dragon Rush',
    'Dragon Tail',
    'Drain Punch',
    'Draining Kiss',
    'Drill Peck',
    'Drill Run',
    'Dual Wingbeat',
    'Dynamax Cannon',
    'Dynamic Punch',
    'Earth Power',
    'Earthquake',
    'Echoed Voice',
    'Eerie Impulse',
    'Electro Ball',
    'Electroweb',
    'Ember',
    'Encore',
    'Endeavor',
    'Endure',
    'Energy Ball',
    'Eruption',
    'Expanding Force',
    'Explosion',
    'Extrasensory',
    'Extreme Speed',
    'Facade',
    'Fairy Wind',
    'Fake Out',
    'Fake Tears',
    'False Swipe',
    'Fell Stinger',
    'Fiery Dance',
    'Fiery Wrath',
    'Final Gambit',
    'Fire Blast',
    'Fire Fang',
    'Fire Lash',
    'Fire Punch',
    'Fire Spin',
    'First Impression',
    'Fishious Rend',
    'Fissure',
    'Flail',
    'Flamethrower',
    'Flare Blitz',
    'Flash Cannon',
    'Flatter',
    'Fleur Cannon',
    'Fling',
    'Flip Turn',
    'Fly',
    'Focus Blast',
    'Focus Energy',
    'Follow Me',
    'Foul Play',
    'Freeze-Dry',
    'Freezing Glare',
    'Frost Breath',
    'Fury Cutter',
    'Fusion Flare',
    'Future Sight',
    'Gear Grind',
    'Geomancy',
    'Giga Drain',
    'Giga Impact',
    'Glaciate',
    'Glare',
    'Grass Knot',
    'Grassy Glide',
    'Grav Apple',
    'Gravity',
    'Growl',
    'Growth',
    'Guard Split',
    'Guillotine',
    'Gunk Shot',
    'Gust',
    'Gyro Ball',
    'Harden',
    'Haze',
    'Head Charge',
    'Head Smash',
    'Headbutt',
    'Heal Bell',
    'Heal Pulse',
    'Healing Wish',
    'Heat Crash',
    'Heat Wave',
    'Heavy Slam',
    'Helping Hand',
    'Hex',
    'High Horsepower',
    'High Jump Kick',
    'Horn Drill',
    'Horn Leech',
    'Howl',
    'Hurricane',
    'Hydro Cannon',
    'Hydro Pump',
    'Hyper Beam',
    'Hyper Voice',
    'Hypnosis',
    'Ice Beam',
    'Ice Fang',
    'Ice Punch',
    'Ice Shard',
    'Icicle Crash',
    'Icicle Spear',
    'Icy Wind',
    'Imprison',
    'Infestation',
    'Iron Defense',
    'Iron Head',
    'Iron Tail',
    'Jungle Healing',
    'King’s Shield',
    'Knock Off',
    'Last Resort',
    'Lava Plume',
    'Leaf Blade',
    'Leaf Storm',
    'Leafage',
    'Leech Life',
    'Leech Seed',
    'Leer',
    'Lick',
    'Life Dew',
    'Light Screen',
    'Liquidation',
    'Lovely Kiss',
    'Low Kick',
    'Lunar Dance',
    'Mach Punch',
    'Magma Storm',
    'Magnet Rise',
    'Mean Look',
    'Megahorn',
    'Memento',
    'Metal Burst',
    'Metal Claw',
    'Metal Sound',
    'Meteor Beam',
    'Meteor Mash',
    'Metronome',
    'Milk Drink',
    'Minimize',
    'Mirror Coat',
    'Mist',
    'Misty Explosion',
    'Moonblast',
    'Moongeist Beam',
    'Moonlight',
    'Morning Sun',
    'Mud Shot',
    'Mud-Slap',
    'Muddy Water',
    'Multi-Attack',
    'Mystical Fire',
    'Nasty Plot',
    'Nature’s Madness',
    'Night Shade',
    'Night Slash',
    'Noble Roar',
    'Nuzzle',
    'Outrage',
    'Overdrive',
    'Overheat',
    'Pain Split',
    'Parting Shot',
    'Peck',
    'Perish Song',
    'Phantom Force',
    'Photon Geyser',
    'Plasma Fists',
    'Play Nice',
    'Play Rough',
    'Poison Gas',
    'Poison Jab',
    'Poison Sting',
    'Pollen Puff',
    'Poltergeist',
    'Pound',
    'Powder Snow',
    'Power Gem',
    'Power Split',
    'Power Trip',
    'Power Whip',
    'Power-Up Punch',
    'Precipice Blades',
    'Prismatic Laser',
    'Protect',
    'Psychic Fangs',
    'Psychic',
    'Psycho Cut',
    'Psyshock',
    'Pyro Ball',
    'Quash',
    'Quick Attack',
    'Quick Guard',
    'Quiver Dance',
    'Rage Powder',
    'Rain Dance',
    'Rapid Spin',
    'Razor Leaf',
    'Recover',
    'Reflect',
    'Rest',
    'Reversal',
    'Rising Voltage',
    'Roar of Time',
    'Rock Blast',
    'Rock Slide',
    'Rock Smash',
    'Rock Throw',
    'Rock Tomb',
    'Rock Wrecker',
    'Rollout',
    'Roost',
    'Sacred Fire',
    'Sacred Sword',
    'Sand Attack',
    'Sand Tomb',
    'Scald',
    'Scale Shot',
    'Scary Face',
    'Scorching Sands',
    'Scratch',
    'Screech',
    'Secret Sword',
    'Seed Bomb',
    'Seismic Toss',
    'Shadow Ball',
    'Shadow Claw',
    'Shadow Force',
    'Shadow Sneak',
    'Sheer Cold',
    'Shell Side Arm',
    'Shell Smash',
    'Shift Gear',
    'Shore Up',
    'Sing',
    'Skill Swap',
    'Skitter Smack',
    'Skull Bash',
    'Sky Attack',
    'Slack Off',
    'Slash',
    'Sleep Powder',
    'Sleep Talk',
    'Sludge Bomb',
    'Sludge Wave',
    'Smack Down',
    'Smart Strike',
    'Smog',
    'Smokescreen',
    'Snarl',
    'Snipe Shot',
    'Soak',
    'Soft-Boiled',
    'Solar Beam',
    'Solar Blade',
    'Spacial Rend',
    'Sparkling Aria',
    'Spikes',
    'Spiky Shield',
    'Spirit Break',
    'Spite',
    'Splash',
    'Spore',
    'Stealth Rock',
    'Steam Eruption',
    'Steel Beam',
    'Steel Roller',
    'Steel Wing',
    'Sticky Web',
    'Stockpile',
    'Stomp',
    'Stomping Tantrum',
    'Stone Edge',
    'Stored Power',
    'Storm Throw',
    'Strength Sap',
    'String Shot',
    'Struggle Bug',
    'Stun Spore',
    'Substitute',
    'Sucker Punch',
    'Sunny Day',
    'Sunsteel Strike',
    'Super Fang',
    'Superpower',
    'Supersonic',
    'Surf',
    'Surging Strikes',
    'Swallow',
    'Sweet Kiss',
    'Sweet Scent',
    'Switcheroo',
    'Swords Dance',
    'Synthesis',
    'Tackle',
    'Tail Slap',
    'Tail Whip',
    'Tailwind',
    'Take Down',
    'Taunt',
    'Teleport',
    'Thousand Arrows',
    'Thrash',
    'Throat Chop',
    'Thunder Cage',
    'Thunder Fang',
    'Thunder Punch',
    'Thunder Shock',
    'Thunder Wave',
    'Thunder',
    'Thunderbolt',
    'Thunderous Kick',
    'Topsy-Turvy',
    'Torment',
    'Toxic Spikes',
    'Toxic',
    'Transform',
    'Tri Attack',
    'Trick Room',
    'Trick',
    'Trick-or-Treat',
    'Triple Axel',
    'Twister',
    'U-turn',
    'Uproar',
    'V-create',
    'Vise Grip',
    'Volt Switch',
    'Water Gun',
    'Water Pulse',
    'Water Spout',
    'Waterfall',
    'Weather Ball',
    'Whirlpool',
    'Whirlwind',
    'Wicked Blow',
    'Wide Guard',
    'Wild Charge',
    'Will-O-Wisp',
    'Wish',
    'Withdraw',
    'Wood Hammer',
    'Worry Seed',
    'Wrap',
    'Wring Out',
    'X-Scissor',
    'Yawn',
    'Zen Headbutt',
  ],
  GAMES: ['swsh', 'bdsp', 'la', 'oras', 'sv'],
  TIERS: ['1v1', 'Doubles', 'OU', 'Uber'],
  BUILDS: [
    'Agility',
    'Air Balloon',
    'All-out Attacker',
    'Assault Vest',
    'Attack Booster',
    'Bigsharp',
    'Booster Energy',
    'Booster Energy (Attack)',
    'Booster Energy (Speed)',
    'Bulk Up',
    'Bulky Attacker',
    'Bulky Dragon Dance',
    'Bulky Lead',
    'Bulky Pivot',
    'Bulky Utility',
    'Bulky Wall',
    'Calm Mind',
    'Charm',
    'Choice Band',
    'Choice Item',
    'Choice-locked Attacker',
    'Choice Scarf',
    'Choice Specs',
    'Clear Amulet',
    'Curse',
    'Defensive',
    'Defensive Hazards',
    'Defensive Pivot',
    'Defensive Roseli Berry',
    'Defensive Utility',
    'Defensive Weakness Policy',
    'Defensive Will-O-Wisp',
    'Double Dance',
    'Dragon Dance',
    'Dual Screens',
    'Dual STABs',
    'Encore + Disable',
    'Endeavor',
    'Fake Tears Set Up',
    'Fast Attacker',
    'Fast Stealth Rock',
    'Fast Supporter',
    'Feesh (Offensive Wallbreaker)',
    'Focus Sash',
    'Grassy Terrain',
    'Hazards Setter',
    'Healing Wish',
    'Heavy-Duty Boots',
    'Hex',
    'Imposter',
    'Iron Defense',
    'IronPress',
    'Lead',
    'Lum Berry',
    'Mixed',
    'Mixed Offense',
    'Moody',
    'Nasty Plot',
    'Offensive',
    'Offensive Dragon Dance',
    'Offensive Supporter',
    'Offensive Swords Dance',
    'Offensive Utility',
    'Physically Defensive',
    'Physically Offensive',
    'Pink Blob',
    'Pivot',
    'Quiver Dance',
    'Rain Breaker',
    'Rain Setter',
    'Rain Sweeper',
    'Redirection Support',
    'Revival Blessing',
    'Rocky Helmet',
    'Roseli Berry',
    'Sand Rush Attacker',
    'Set-up Attacker',
    'Setup Sweeper',
    'Shed Tail',
    'Shed Tail Support',
    'Shell Smash',
    'Shell Smash Sweeper',
    'Sitrus Berry',
    'Skill',
    'Slush Rush Sweeper',
    'Special Attacker',
    'Specially Defensive',
    'Specially Defensive Calm Mind',
    'Specially Defensive Pivot',
    'Specially Defensive Roseli Berry',
    'Specially Defensive Weakness Policy',
    'Spikes Lead',
    'Stall',
    'Stealth Rock',
    'Sticky Web',
    'Suicide Lead',
    'Sun Supporter',
    'Sun Sweeper',
    'Swift Swim Attacker',
    'Swords Dance',
    'Swords Dance + Flame Charge',
    'Tailwind Setter',
    'Tank',
    'Tera Blast',
    'Terrain Setter',
    'Thanos',
    'The Pex',
    'Tidy Up',
    'Trapper',
    'TR Attacker',
    'Trick',
    'Trick + Disable',
    'Trick Room Attacker',
    'Trick Room Setter',
    'TR Supporter',
    'Unburden Sweeper',
    'Utility',
    'Utility Supporter',
    'Wall',
    'Wallbreaker',
    'Weakness Policy',
  ],
};

export default constants;
