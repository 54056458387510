import config from '../config.js'
function ItemCard({image, name}) {
  return (
    <div className='bg-eevee flex place-items-center' style={{
      width: '400px',
      height: '240px',
      backgroundImage: `url(${config.urls.gen}/v2/background.gif)`
      }}>
      <img className='mx-auto' alt={name} src={`${config.urls.gen}/v2/items/${image}.png`} />
    </div>
  )
}

export default ItemCard;
