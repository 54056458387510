import Page from '../components/Page.js';
import Banner from '../components/Banner.js';
import config from '../config.js';

import card from '../images/logos/card.webp';
import ds from '../images/logos/ds.webp';
import special from '../images/logos/special.webp';
import trading from '../images/logos/trading.webp';
import pentagon from '../images/logos/pentagon.webp';

import { Link } from 'react-router-dom';

const badges = [
  {
    title: 'super fast trading',
    description: 'most orders serviced within 24 hours',
    image: trading,
  },
  {
    title: 'competitive pokemon',
    description: 'never lose again with our movesets',
    image: pentagon,
  },
  {
    title: 'special pokemon',
    description: 'choose from our selection of rare event and shiny Pokemon',
    image: special,
  },
  {
    title: 'worry-free payments',
    description: 'secure and easy checkout with Stripe',
    image: card,
  },
  {
    title: 'expert product support',
    description: 'contact us with requests, questions, and feedback',
    image: ds,
  },
];

function Home() {
  return (
    <Page title="Welcome to Pokezon!">
      <Banner />
      <div className="my-10" />
    </Page>
  );
}

export default Home;
