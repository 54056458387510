import axios from 'axios';
import { useState, useEffect } from 'react';
import config from './config.js';

function copyToClipboard(text) {
  // create element to hold text
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // position at top-left of screen to avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  // add element to DOM
  document.body.appendChild(textArea);

  // select and copy from element
  textArea.focus();
  textArea.select();
  document.execCommand('copy');

  // cleanup
  document.body.removeChild(textArea);
}

function fetchResource(resource, setter) {
  return axios.get(`${config.urls.back}/${resource}`).then((res) => {
    let data = [];
    if (res.data?.data) data = res.data.data;
    else data = res.data;

    if (setter) setter(data);
    return data;
  });
}

function fetchEntries(setEntries, chrono, setFetching) {
  if (setFetching) setFetching(true);
  axios.get(`${config.urls.back}/listing_entry`).then((res) => {
    setEntries(
      chrono
        ? res.data.sort((a, b) => b.Id - a.Id)
        : res.data.sort((a, b) => a.Id - b.Id),
    );
    if (setFetching) setFetching(false);
  });
}

async function fetchOrders(setOrders, setFetching, setFetchingExtra) {
  if (setFetching) setFetching(true);
  if (setFetchingExtra) setFetchingExtra(true);

  const dbOrders = await axios
    .get(`${config.urls.back}/order`)
    .then((res) => res?.data?.map((o) => ({ ...o, loading: false })));
  setOrders(dbOrders);

  if (setFetching) setFetching(false);

  const remainingOrders = await axios
    .get(`${config.urls.back}/order?rest=true`)
    .then((res) => res?.data?.map((o) => ({ ...o, loading: false })));

  setOrders([...dbOrders, ...remainingOrders]);

  if (setFetchingExtra) setFetchingExtra(false);
}

// https://pokepast.es/syntax.html
function parsePaste(paste, speciesMap) {
  const result = {
    ball: 'pokéball',
    factor: 'regular',
    item_name: '',
    pokemon_id: '',
    ev: [0, 0, 0, 0, 0, 0],
    iv: [31, 31, 31, 31, 31, 31],
    moves: ['', '', '', ''],
    ability: '',
    nature: '',
    level: 100,
    rarity: 'regular',
    gender: '',
    note: '',
    stripe_product_id_override: '',
    gen: 9,
    game: 'sv',
    alpha: false,
    thumb: '',
    gif: '',
  };
  let moveIndex = 0;
  paste.split('\n').forEach((line, i) => {
    // first line will always be species / gender / info
    if (i === 0) {
      let [, species, nickname, gender, item_name] = line.match(
        /([^(]+) ?(\(\w+\))? ?(\(\w\))? ?(@.+)?/,
      );

      // nickname precedes species when present, otherwise species is first
      if (nickname) {
        nickname = nickname.slice(1, -1);
        [species, nickname] = [nickname, species.trim()];
        result.note = `Nickname is "${nickname}"`;
      }

      if (gender) gender = { M: 'male', F: 'female' }[gender[1]];
      else gender = 'genderless';

      if (!item_name) item_name = '';

      result.pokemon_id = speciesMap[species].base;
      result.gender = gender;
      result.item_name = item_name.replace('@', '').trim();
    }

    // other lines are unstable
    else {
      if (line.startsWith('Ability')) result.ability = line.split(':')[1].trim();
      if (line.endsWith('Nature')) result.nature = line.replace(' Nature', '').toLowerCase();
      if (line.startsWith('Level')) result.level = Number(line.split(':')[1].trim());
      if (line.startsWith('EVs')) {
        const stats = line.split(':')[1].split('/');
        stats.forEach((stat) => {
          const [n, s] = stat.trim().split(' ');
          result.ev[
            {
              HP: 0,
              Atk: 1,
              Def: 2,
              SpA: 3,
              SpD: 4,
              Spe: 5,
            }[s]
          ] = n;
        });
      }
      if (line.startsWith('IVs')) {
        const stats = line.split(':')[1].trim().split('/');
        stats.forEach((stat) => {
          const [n, s] = stat.trim().split(' ');
          result.iv[
            {
              HP: 0,
              Atk: 1,
              Def: 2,
              SpA: 3,
              SpD: 4,
              Spe: 5,
            }[s]
          ] = n;
        });
      }
      if (line.startsWith('Shiny')) {
        result.rarity = {
          No: 'regular',
          Yes: 'shiny',
          'Yes (Square)': 'square',
        }[line.split(':')[1].trim()];
      }
      if (line.startsWith('Gigantamax')) {
        result.factor = { No: 'regular', Yes: 'gigantamax' }[
          line.split(':')[1].trim()
        ];
      }
      if (line.startsWith('Alpha')) result.alpha = { No: false, Yes: true }[line.split(':')[1].trim()];
      if (line.startsWith('-')) result.moves[moveIndex++] = line.replace('-', '').trim();
    }
  });

  return result;
}

function capitalize(s) {
  return s ? s[0].toUpperCase() + s.substring(1).toLowerCase() : s;
}

function without(x, list) {
  return list.slice(0, list.indexOf(x)).concat(list.slice(list.indexOf(x) + 1));
}

function keyOf(value, object) {
  return Object.keys(object).find((key) => object[key] == value);
}

function stats(base, ev, iv, nature, level) {
  // key: hp:0, atk:1, def:2, sp_atk:3, sp_def:4, spd:5
  // reference: https://m.bulbapedia.bulbagarden.net/wiki/Stat

  // define nature factors
  const natureFactors = Array(6).fill(1);
  const applyDiff = (up, down) => {
    natureFactors[up] = 1.1;
    natureFactors[down] = 0.9;
  };

  switch (nature) {
    default:
    case 'hardy':
    case 'docile':
    case 'serious':
    case 'bashful':
    case 'quirky':
      break; // no change to stats

    case 'lonely':
      applyDiff(1, 2);
      break;
    case 'brave':
      applyDiff(1, 5);
      break;
    case 'adamant':
      applyDiff(1, 3);
      break;
    case 'naughty':
      applyDiff(1, 4);
      break;
    case 'bold':
      applyDiff(2, 1);
      break;
    case 'relaxed':
      applyDiff(2, 5);
      break;
    case 'impish':
      applyDiff(2, 3);
      break;
    case 'lax':
      applyDiff(2, 4);
      break;
    case 'timid':
      applyDiff(5, 1);
      break;
    case 'hasty':
      applyDiff(5, 2);
      break;
    case 'jolly':
      applyDiff(5, 3);
      break;
    case 'naive':
      applyDiff(5, 4);
      break;
    case 'modest':
      applyDiff(3, 1);
      break;
    case 'mild':
      applyDiff(3, 2);
      break;
    case 'quiet':
      applyDiff(3, 5);
      break;
    case 'rash':
      applyDiff(3, 4);
      break;
    case 'calm':
      applyDiff(4, 1);
      break;
    case 'gentle':
      applyDiff(4, 2);
      break;
    case 'sassy':
      applyDiff(4, 5);
      break;
    case 'careful':
      applyDiff(4, 3);
      break;
  }

  // handle arrays with too few elements
  if (base.length < 6) {
    console.warn(`got base array of length ${base.length}, assuming 0's`);
    base = base.concat(Array(6 - base.length).fill(0));
  }

  if (ev.length < 6) {
    console.warn(`got ev array of length ${ev.length}, assuming 0's`);
    ev = ev.concat(Array(6 - ev.length).fill(0));
  }

  if (iv.length < 6) {
    console.warn(`got iv array of length ${iv.length}, assuming 0's`);
    iv = iv.concat(Array(6 - iv.length).fill(0));
  }

  // hp, atk, def, sp_atk, sp_def, spd
  const results = Array(6).fill(undefined);

  // hp
  results[0] = Math.floor(
    ((2 * Number(base[0]) + Number(iv[0]) + Math.floor(Number(ev[0]) / 4))
        * Number(level))
        / 100,
  )
    + Number(level)
    + 10;

  // others
  const otherStat = (i) => Math.floor(
    (Math.floor(
      ((2 * base[i] + iv[i] + Math.floor(ev[i] / 4)) * level) / 100,
    )
        + 5)
        * natureFactors[i],
  );
  [1, 2, 3, 4, 5].forEach((n) => {
    results[n] = otherStat(n);
  });

  return {
    hp: results[0],
    attack: results[1],
    defense: results[2],
    sp_attack: results[3],
    sp_defense: results[4],
    speed: results[5],
  };
}

function entryToTitle(entry) {
  if (!entry) return 'Loading';
  if (isCustom(entry)) return 'Fully Customized Pokemon';

  let result = '';
  if (entry.Rarity === 1) result += 'Shiny ';
  else if (entry.Rarity === 2) result += 'Square Shiny ';

  result += entry.Species;

  const fullIV = entry.Iv.filter((iv) => iv === 31).length;
  if (fullIV === 5) result += ' 5IV';
  else if (fullIV === 6) result += ' 6IV';

  result += ` ${entry.Ability} ${capitalize(entry.Nature)}`;

  if (entry.Factor === 1) result += ' Dynamax';
  else if (entry.Factor === 2) result += ' Gigantamax';

  return result;
}

function daysFrom(days, date = Date.now()) {
  return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
}

function daysFromNow(days) {
  return new Date(Date.now() + days * 24 * 60 * 60 * 1000);
}

function isMultigame(entries) {
  return (
    Array.from(new Set(entries.map((e) => e.Game).filter((g) => g?.length)))
      .length > 1
  );
}

function gameToLabel(game, short = false) {
  if (short) {
    return (
      {
        swsh: 'Sword/Shield',
        bdsp: 'BD/SP',
        la: 'Legends Arceus',
        oras: 'OR/AS',
        sv: 'Scarlet/Violet',
      }[game] ?? ''
    );
  }

  return (
    {
      swsh: 'Pokemon Sword / Shield',
      bdsp: 'Pokemon Brilliant Diamond / Shining Pearl',
      la: 'Pokemon Legends: Arceus',
      oras: 'Pokemon Omega Ruby / Alpha Saphire',
      sv: 'Pokemon Scarlet / Violet',
    }[game] ?? ''
  );
}

function pokedexToMiniURL(pokedex) {
  return `${config.urls.gen}/v2/mini/${pokedex}.png`;
}

function stat_percents(base, ev, iv, nature, level) {
  const {
    hp, attack, defense, sp_attack, sp_defense, speed,
  } = stats(
    base,
    ev,
    iv,
    nature,
    level,
  );
  return {
    hp: (hp / 714) * 100,
    attack: Math.ceil((attack / 504) * 100),
    defense: Math.ceil((defense / 614) * 100),
    sp_attack: Math.ceil((sp_attack / 504) * 100),
    sp_defense: Math.ceil((sp_defense / 614) * 100),
    speed: Math.ceil((speed / 504) * 100),
  };
}

function scrollToTag(tag) {
  document.getElementsByTagName(tag)[0].scrollIntoView(false);
}

function formatRarity(rarity) {
  return {
    0: 'Regular',
    1: 'Shiny',
    2: 'Square Shiny',
  }[rarity];
}

function configurationToTitle(configuration) {
  if (!configuration) return 'Loading';
  if (configuration.NameOverride) return configuration.NameOverride;

  const rarity = bundleConfigurationRarity(configuration);
  return formatRarity(rarity);
}

function bundleToTitle(bundle, configuration) {
  let result = configurationToTitle(configuration);
  result += ` ${bundle.Name}`;

  return result;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function entryToMiniURL(entry) {
  return `${config.urls.gen}/v2/mini/${entry.Pokedex}${
    entry.Form ? `_${entry.Form.toLowerCase().replace(' ', '-')}` : ''
  }.png`;
}

function bundleConfigurationRarity(configuration) {
  if (!configuration) return 0;

  // if all rarities match, return shared rarity
  if (
    configuration.Entries.every(
      (e) => e.Rarity === configuration.Entries[0].Rarity,
    )
  ) {
    return configuration.Entries[0].Rarity;
  }

  // if not, return minimum rarity

  return Math.min(configuration.Entries.map((e) => e.Rarity));
}

function bundleConfigurationGame(configuration) {
  if (!configuration) return 0;

  // if all games match, return shared game
  if (
    configuration.Entries.every((e) => e.Game === configuration.Entries[0].Game)
  ) {
    return configuration.Entries[0].Game;
  }

  // if not, return first game (yeah yeah, this is the same behavior. this used to return minimum gen, but that logic would now involve uncomfortable sorting; maybe later

  return configuration.Entries[0].Game;
}

function gameToPosition(game) {
  return {
    swsh: 0,
    bdsp: 1,
    la: 2,
    oras: 3,
    sv: 4,
  }[game];
}

function isCustom(entry) {
  return entry?.Id < 15;
}

// https://stackoverflow.com/questions/11093908/ellipsis-to-truncate-a-long-text
function shorten(text, maxLength) {
  let ret = text;
  if (ret.length > maxLength) {
    ret = `${ret.substr(0, maxLength - 3)}...`;
  }
  return ret;
}

function isFlatPricedEntryId(id) {
  return [
    3,
    6,
    8,
    9, // la
    12,
    13,
    14, // sv
  ].includes(id);
}

export {
  copyToClipboard,
  fetchResource,
  fetchEntries,
  fetchOrders,
  parsePaste,
  capitalize,
  without,
  keyOf,
  stats,
  daysFrom,
  daysFromNow,
  entryToTitle,
  isMultigame,
  gameToLabel,
  isCustom,
  pokedexToMiniURL,
  stat_percents,
  scrollToTag,
  formatRarity,
  configurationToTitle,
  bundleToTitle,
  useWindowDimensions,
  entryToMiniURL,
  bundleConfigurationRarity,
  bundleConfigurationGame,
  gameToPosition,
  shorten,
  isFlatPricedEntryId,
};
