import Page from '../components/Page.js';
import hardhat from '../images/hardhat.webp';

import { Link } from 'react-router-dom';;

function UnderConstruction() {
  return (
    <Page title='Under Construction'>
      <div className='min-h-full flex flex-col place-content-between'>
        <div className='h-40 flex flex-col place-items-center w-full p-40'>
          <div className='text-3xl text-black mb-5'>Under Construction 🔨</div>
          <div>This page is still under construction, Eevee looks like she's focusing on something...</div>
          <div>We shouldn't distract her, let's <Link className='text-pokezon underline' to='/pokemon'>browse Pokemon</Link> instead!</div>
        </div>
        <img src={hardhat} className='absoulte mx-auto' width='300px' />
      </div>
    </Page>
  )
}

export default UnderConstruction;
