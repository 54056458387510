import Button from './Button.js';
import Rarity from './Rarity.js';
import { entryToMiniURL, bundleConfigurationRarity } from 'util';

import { Link } from 'react-router-dom';

function BundleTableRow({ bundle, configuration }) {
  return (
    <tr>
      <td className="pb-8">
        <div className="flex flex-col place-items-center mr-auto whitespace-nowrap">
          <Link
            className="underline text-pokezon z-5"
            to={`/bundles/${bundle.Id}?c=${configuration.Id}`}
          >
            {configuration.NameOverride ??
              `${
                { 0: '', 1: 'Shiny ', 2: 'Square Shiny ' }[
                  bundleConfigurationRarity(configuration)
                ]
              }${bundle.Name}`}
          </Link>

          <div className="flex flex-wrap gap-1 -mt-3 w-max max-w-lg">
            {configuration.Entries.sort((a, b) => a.Pokedex - b.Pokedex).map(
              entry => (
                <div title={entry.Species}>
                  <img
                    alt={`Sprite of ${entry.Species}`}
                    key={`configuration-${configuration.Id}-entry-${entry.Id}-img`}
                    src={entryToMiniURL(entry)}
                    className="h-20 w-auto"
                  />
                </div>
              ),
            )}
          </div>
        </div>
      </td>

      <td>
        <Rarity value={bundleConfigurationRarity(configuration)} />
      </td>

      <td>
        <Link to={`/bundles/${bundle.Id}?c=${configuration.Id}`}>
          <Button text="Details" className="mt-2" />
        </Link>
      </td>
    </tr>
  );
}

export default BundleTableRow;
