import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Button({onClick, text, icon, className = '', disabled = false, color='pokezon'}) {
  return (
        <button
          className={'rounded-md text-white py-2 px-2 whitespace-nowrap ' + (!disabled ? `bg-${color}` : 'bg-gray-400 pointer-events-none') + ' ' + className}
          type='button'
          onClick={onClick}
        >
          <span>
            {icon?.length === 2 && <FontAwesomeIcon className='m-auto' icon={icon} />}
            <span className='mx-2'>{text}</span>
          </span>
        </button>
  );
}

export default Button;
