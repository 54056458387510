import Page from '../components/Page.js';
import StatGraph from '../components/StatGraph.js';
import Section from '../components/Section.js';
import Compatibility from '../components/Compatibility.js';
import PokemonLoader from '../components/PokemonLoader.js';
import Table from '../components/Table.js';
import config from '../config.js';
import {
  capitalize,
  stats,
  stat_percents,
  entryToTitle,
  gameToLabel,
  gameToPosition,
  formatRarity,
  useWindowDimensions,
  isCustom,
} from 'util';
import { Small, NotSmall } from '../responsive.js';

import axios from 'axios';
import Modal from 'react-modal';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form, Field } from 'formik';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import qs from 'qs';

function Listing({ location }) {
  const { e: queryEntryId } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const { listing_id } = useParams();
  const [listing, setListing] = useState();
  const [currentEntry, setCurrentEntry] = useState();
  const [metaModalIsOpen, setMetaModalIsOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [alpha, setAlpha] = useState(false);
  const [rarity, setRarity] = useState();
  const [tier, setTier] = useState();
  const { width } = useWindowDimensions();
  const getMatchingEntries = ({
    gameFilter = currentEntry?.Game,
    rarityFilter = rarity,
    tierFilter = tier,
  } = {}) =>
    listing?.ListingEntries?.filter(
      e =>
        e.Game == gameFilter &&
        !e.Alpha &&
        e.Rarity == rarityFilter &&
        e.Tier == tierFilter,
    )?.sort((a, b) => {
      if (a.Factor !== b.Factor) {
        if (!a.Factor) return -1;
        else if (!b.Factor) return 1;
        else return b.Factor > a.Factor ? -1 : 1;
      }
      if (a.Form !== b.Form) {
        if (!a.Form) return -1;
        else if (!b.Form) return 1;
        else return b.Form > a.Form ? -1 : 1;
      }
      if (a.Label !== b.Label) {
        if (!a.Label) return -1;
        else if (!b.Label) return 1;
        else return b.Label > a.Label ? -1 : 1;
      }
      if (a.Rarity !== b.Rarity) return a.Rarity - b.Rarity;
      if (a.Alpha !== b.Alpha) return a.Alpha - b.Alpha;
      return 0;
    }) || [];
  const updateCurrentEntry = ({
    gameFilter,
    rarityFilter,
    tierFilter,
  } = {}) => {
    const firstEntry = getMatchingEntries({
      gameFilter,
      rarityFilter,
      tierFilter,
    })?.[0];
    if (!firstEntry) return;
    setCurrentEntry(firstEntry);
    window.history.pushState(
      {},
      window.location.href,
      listing_id + '?e=' + firstEntry.Id,
    );
  };

  useEffect(() => {
    if (listing_id)
      axios.get(config.urls.api + '/listing/' + listing_id).then(res => {
        const l = res.data;
        const e =
          l.ListingEntries.find(entry => entry.Id === Number(queryEntryId)) ||
          l.ListingEntries.filter(e => !e.Alpha).reverse()[0];

        setListing(l);
        setCurrentEntry(e);
        setRarity(e.Rarity);
        setTier(e.Tier);
        if (Number(listing_id) === 1)
          document.title = 'Fully Custom Pokemon - Pokezon';
        else
          document.title =
            { 0: '', 1: 'Shiny ', 2: 'Square Shiny ' }[e.Rarity] +
            e.Species +
            ` (${gameToLabel(e.Game, true)})` +
            ' - Pokezon';
      });
  }, [listing_id]);

  return (
    <Page title={listing ? listing.Species : ''}>
      <div className="mx-auto sm:p-5 lg:w-3/4 text-gray-800">
        <div className="flex flex-wrap gap-4">
          <div className="flex flex-wrap md:flex-nowrap gap-2">
            <Section className="mx-auto">
              <div className="flex flex-wrap justify-between">
                <div>
                  <div className="text-lg sm:text-2xl">
                    {listing ? entryToTitle(currentEntry) : 'Loading...'}
                  </div>
                  <div className="text-xs sm:text-sm">
                    <span className="text-gray-600">Compatible with: </span>
                    <span>{gameToLabel(currentEntry?.Game)}</span>
                  </div>
                </div>

                <NotSmall>
                  <Compatibility game={currentEntry?.Game} />
                </NotSmall>
              </div>

              <hr className="my-1 sm:my-4" />

              <div className="flex flex-wrap gap-6">
                <div className="flex flex-col place-items-center mx-auto">
                  <NotSmall>
                    <PokemonLoader
                      entry_id={currentEntry?.Id}
                      parentData={currentEntry}
                    />
                  </NotSmall>

                  <Small>
                    <div
                      style={{
                        marginLeft: -30 * Math.min((width / 400).toFixed(1), 1),
                      }}
                    >
                      <PokemonLoader
                        entry_id={currentEntry?.Id}
                        parentData={currentEntry}
                        scale={Math.min((width / 550).toFixed(1) + '5', 1)}
                      />
                    </div>
                  </Small>

                  {listing?.Id !== 1 &&
                    (submitted ? (
                      <div className="text-xs text-gray-500">
                        Thanks for helping out!
                      </div>
                    ) : (
                      <button
                        type="button"
                        onClick={() => setMetaModalIsOpen(true)}
                      >
                        <div className="text-xs text-gray-500 underline">
                          Is my meta out of date?
                        </div>
                      </button>
                    ))}

                  <Modal
                    ariaHideApp={false}
                    className="rounded-3xl p-5 bg-eevee mx-auto mt-24 w-min text-white"
                    isOpen={metaModalIsOpen}
                    onRequestClose={() => setMetaModalIsOpen(false)}
                    contextLabel="How?"
                  >
                    <Formik
                      id="feedback"
                      initialValues={{ message: '', email: '' }}
                      validationSchema={yup.object().shape({
                        message: yup
                          .string()
                          .required('Please fill out your message'),
                        email: yup.string().email('Please enter a valid email'),
                      })}
                      onSubmit={values => {
                        axios.post(config.urls.api + '/feedback', {
                          type: 'meta',
                          message: values.message,
                          email: values.email ?? undefined,
                          entry_id: currentEntry?.Id,
                        });

                        setMetaModalIsOpen(false);
                        setSubmitted(true);
                      }}
                    >
                      <Form>
                        <div className="flex flex-col place-items-center gap-5 text-gray-700">
                          <div>
                            <label className="block mb-1" for="message">
                              How should we improve?
                            </label>
                            <Field
                              className="block w-96 p-1"
                              id="message"
                              as="textarea"
                              rows={4}
                              name="message"
                              placeholder="Smogon link, YouTube video, helpful advice..."
                            />
                          </div>

                          <div>
                            <label for="email">Email (optional): </label>
                            <Field
                              className="p-1"
                              id="email"
                              type="email"
                              name="email"
                            />
                          </div>

                          <button
                            type="submit"
                            className="mx-auto rounded-md p-2 bg-pokezon text-white"
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    </Formik>
                  </Modal>
                </div>

                <div className="flex flex-wrap lg:flex-col gap-5">
                  <ul className="list-disc whitespace-nowrap w-min -mb-4 sm:mb-0">
                    {(isCustom(currentEntry)
                      ? [
                          'Completely customizable',
                          'Order any build you want',
                          <>
                            Must be provided in{' '}
                            <a
                              className="text-pokezon underline"
                              href="https://play.pokemonshowdown.com/teambuilder"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Showdown format
                            </a>
                          </>,
                        ]
                      : bullets(currentEntry)
                    ).map(text => (
                      <li>{text}</li>
                    ))}
                  </ul>

                  <div>
                    <span>
                      Game:
                      <div className="flex gap-2">
                        {listing?.ListingEntries
                          ? Array.from(
                              new Set(
                                listing.ListingEntries.map(({ Game }) => Game),
                              ),
                            )
                              .sort(
                                (a, b) => gameToPosition(a) - gameToPosition(b),
                              )
                              .map(game => (
                                <button
                                  className={`py-2 md:py-0 px-1 border-2 rounded-md ${
                                    game === currentEntry?.Game
                                      ? 'border-pokezon'
                                      : 'border-gray-400'
                                  }`}
                                  type="button"
                                  onClick={() => {
                                    updateCurrentEntry({
                                      gameFilter: game,
                                    });
                                    setAlpha(false);
                                  }}
                                >
                                  {gameToLabel(game, true)}
                                </button>
                              ))
                          : 'Loading'}
                      </div>
                    </span>

                    <div className="mt-4">
                      Rarity:
                      <div className="flex gap-2">
                        {listing?.ListingEntries
                          ? [0, 1, 2].map(rarityOption => (
                              <button
                                className={`py-2 md:py-0 px-1 border-2 rounded-md ${
                                  rarityOption === rarity
                                    ? 'border-pokezon'
                                    : 'border-gray-400'
                                }`}
                                type="button"
                                onClick={() => {
                                  setRarity(rarityOption);
                                  updateCurrentEntry({
                                    rarityFilter: rarityOption,
                                  });
                                }}
                              >
                                {formatRarity(rarityOption)}
                              </button>
                            ))
                          : 'Loading'}
                      </div>
                    </div>

                    {currentEntry?.Game === 'la' &&
                      listing?.ListingEntries.some(e => e.Alpha) && (
                        <div className="mt-4">
                          Alpha:
                          <input
                            className="ml-2"
                            type="checkbox"
                            checked={alpha}
                            onClick={() => {
                              setAlpha(!alpha);
                              const newEntry = listing.ListingEntries.find(
                                e =>
                                  e.Game === currentEntry.Game &&
                                  e.Rarity === currentEntry.Rarity &&
                                  e.Alpha === !alpha,
                              );
                              setCurrentEntry(newEntry);
                              window.history.pushState(
                                {},
                                window.location.href,
                                listing_id + '?e=' + newEntry.Id,
                              );
                            }}
                          />
                        </div>
                      )}

                    {listing?.ListingEntries?.some(e => e.Tier) && (
                      <div className="mt-4">
                        Tier:
                        <div className="flex gap-2">
                          {listing?.ListingEntries
                            ? [
                                ...new Set(
                                  listing.ListingEntries.map(e => e.Tier),
                                ),
                              ].map(tierOption => {
                                const disabled =
                                  getMatchingEntries({
                                    tierFilter: tierOption,
                                  }).length === 0;
                                return (
                                  <button
                                    className={`py-2 md:py-0 px-1 border-2 rounded-md ${
                                      tierOption === tier
                                        ? 'border-pokezon'
                                        : 'border-gray-400'
                                    } ${
                                      disabled
                                        ? 'cursor-not-allowed opacity-30'
                                        : ''
                                    }`}
                                    type="button"
                                    disabled={disabled}
                                    onClick={() => {
                                      setTier(tierOption);
                                      updateCurrentEntry({
                                        tierFilter: tierOption,
                                      });
                                    }}
                                  >
                                    {tierOption || '(none)'}
                                  </button>
                                );
                              })
                            : 'Loading'}
                        </div>
                      </div>
                    )}

                    <div className="mt-4">
                      Build:
                      <div className="flex flex-wrap gap-2 max-w-md">
                        {listing?.ListingEntries
                          ? getMatchingEntries().map(
                              (entry, index, processedEntries) => (
                                <>
                                  {
                                    /* never left bar on first button */
                                    index !== 0 &&
                                      /* beginning of new group */
                                      ((entry.Rarity === 0 &&
                                        entry.Form) /* non-shiny usually means new group */ ||
                                        processedEntries[index - 1].Label !==
                                          entry.Label) /* new label means new group */ && (
                                        <div className="border-2 h-max" />
                                      )
                                  }
                                  <button
                                    className={`py-2 md:py-0 px-1 border-2 rounded-md ${
                                      (
                                        alpha
                                          ? entry.Rarity === currentEntry.Rarity
                                          : entry.Id === currentEntry.Id
                                      )
                                        ? 'border-pokezon'
                                        : 'border-gray-400'
                                    }`}
                                    type="button"
                                    onClick={() => {
                                      setCurrentEntry(entry);
                                      window.history.pushState(
                                        {},
                                        window.location.href,
                                        listing_id + '?e=' + entry.Id,
                                      );
                                    }}
                                  >
                                    {entryLabel(
                                      entry,
                                      listing.Id ===
                                        651 /* special case for ditto listing */,
                                    )}
                                  </button>
                                </>
                              ),
                            )
                          : 'Loading'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Section>
          </div>

          {listing?.Id !== 1 && (
            <>
              <Section className="mx-auto">
                <div className="text-xl font-bold">General Information</div>
                <hr className="mb-2" />

                <div className="flex flex-wrap gap-3">
                  <Table
                    rows={
                      currentEntry
                        ? {
                            Species: currentEntry.Species,
                            Pokedex: currentEntry.Pokedex.toString().padStart(
                              3,
                              '0',
                            ),
                            Type: currentEntry.Type.map(t => capitalize(t))
                              .filter(t => t !== 'None')
                              .join(', '),
                            ...(currentEntry.Teratype
                              ? {
                                  'Tera Type': capitalize(
                                    currentEntry.Teratype,
                                  ),
                                }
                              : {}),
                            Gender: capitalize(currentEntry.Gender),
                            Shiny: {
                              0: <span>No</span>,
                              1: (
                                <FontAwesomeIcon
                                  className="m-auto relative"
                                  icon={['fas', 'check']}
                                  color="#0066C0"
                                />
                              ),
                              2: (
                                <span>
                                  <FontAwesomeIcon
                                    className="m-auto relative"
                                    icon={['fas', 'check']}
                                    color="#0066C0"
                                  />{' '}
                                  (Square)
                                </span>
                              ),
                            }[currentEntry.Rarity],
                            ...(currentEntry.Game === 'la'
                              ? { Alpha: currentEntry.Alpha ? 'Yes' : 'No' }
                              : {}), // untested because no alpha LA entries exist
                            Level: currentEntry.Level,
                            Ability: currentEntry.Ability,
                            Nature: capitalize(currentEntry.Nature),
                            Item: currentEntry.ItemName ?? 'None',
                          }
                        : {}
                    }
                  />

                  <div className="flex flex-col gap-3 w-full md:w-auto">
                    <Table
                      rows={
                        currentEntry
                          ? {
                              'Move 1':
                                currentEntry.Moves.length >= 1
                                  ? currentEntry.Moves[0]
                                  : 'None',
                              'Move 2':
                                currentEntry.Moves.length >= 2
                                  ? currentEntry.Moves[1]
                                  : 'None',
                              'Move 3':
                                currentEntry.Moves.length >= 3
                                  ? currentEntry.Moves[2]
                                  : 'None',
                              'Move 4':
                                currentEntry.Moves.length >= 4
                                  ? currentEntry.Moves[3]
                                  : 'None',
                            }
                          : {}
                      }
                    />

                    {currentEntry?.Note && (
                      <div className="text-sm sm:w-56 mx-auto md:ml-0">
                        <div className="text-center md:text-left font-semibold">
                          Note
                        </div>
                        <hr className="my-1" />
                        <div>{currentEntry.Note}</div>
                      </div>
                    )}
                  </div>
                </div>
              </Section>

              <Section className="mx-auto max-w-md">
                <div className="text-xl font-bold">Stats</div>
                <hr className="mb-2" />
                {currentEntry ? (
                  <StatGraph
                    stats={stats(
                      currentEntry.Base,
                      currentEntry.Ev,
                      currentEntry.Iv,
                      currentEntry.Nature,
                      currentEntry.Level,
                    )}
                    percents={stat_percents(
                      currentEntry.Base,
                      currentEntry.Ev,
                      currentEntry.Iv,
                      currentEntry.Nature,
                      currentEntry.Level,
                    )}
                  />
                ) : (
                  'Loading'
                )}
                <div className="text-xs text-gray-400 whitespace-pre-wrap">
                  The ranges shown are based on the highest achievable
                  statistics across all level 100 Pokemon.
                </div>
              </Section>
            </>
          )}
        </div>
      </div>
    </Page>
  );
}

function bullets(entry) {
  if (!entry) return [];

  let result = [];
  if (entry.Iv.filter(iv => iv === 31).length === 6)
    result.push('Max 31 IVs in all 6 stats');

  if (entry.Level >= 50) {
    const percents = stat_percents(
      entry.Base,
      entry.Ev,
      entry.Iv,
      entry.Nature,
      entry.Level,
    );
    const bestPercent = Math.max(...Object.values(percents));
    const highStats = [];
    Object.entries(percents).forEach(([stat, percent]) => {
      if (percent === bestPercent)
        highStats.push(
          {
            hp: 'HP',
            attack: 'Attack',
            defense: 'Defense',
            sp_attack: 'Special Attack',
            sp_defense: 'Special Defense',
            speed: 'Speed',
          }[stat],
        );
    });

    result.push(`High ${highStats.join(' / ')}`);
  } else result.push('Low level - raise it your own way.');

  const optimizedStats = Object.entries(entry.Ev)
    .map(([index, ev]) => {
      if (ev === 252)
        return {
          0: 'HP',
          1: 'Attack',
          2: 'Defense',
          3: 'Special Attack',
          4: 'Special Defense',
          5: 'Speed',
        }[index];
      else return undefined;
    })
    .filter(stat => stat);
  if (optimizedStats.length)
    result.push(`Optimized for ${optimizedStats.join(' / ')}`);

  if (entry.ItemName) result.push(`Includes item: ${entry.ItemName}`);

  return result;
}

function entryLabel(entry, more = false) {
  let result = '';
  if (entry.Build) return entry.Build;
  if (entry.Label) return entry.Label;

  if (entry.Alpha) result += 'Alpha ';
  if (entry.Factor) result += { 1: 'Dynamax', 2: 'Gmax' }[entry.Factor] + ' ';
  if (entry.Form) result += entry.Form + ' ';

  if (more) result += `${entry.Ability} ${capitalize(entry.Nature)} `;

  return result.trim() || 'Standard';
}

export default Listing;
