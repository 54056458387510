import config from '../config.js';

function Compatibility({game}) {
  return (
    <div className='flex h-12 gap-x-2 mr-0'>
      {{
        '': <></>,
        'bdsp': (
          <>
            <img className='w-full h-12' src={config.urls.gen + '/v2/games/bd.png'} />
            <img className='w-full h-12' src={config.urls.gen + '/v2/games/sp.png'} />
          </>
        ),
        'xy': (
          <>
            <img className='w-full h-12' src={config.urls.gen + '/v2/games/xy.png'} />
            <img className='w-full h-12' src={config.urls.gen + '/v2/games/oras.png'} />
          </>
        ),
        'usum': (
          <>
            <img className='w-full h-12' src={config.urls.gen + '/v2/games/sunmoon.png'} />
            <img className='w-full h-12' src={config.urls.gen + '/v2/games/usum.png'} />
          </>
        ),
        'swsh': <img className='w-full h-12' src={config.urls.gen + '/v2/games/swordshield.png'} />,
        'la': <img className='w-full h-12' src={config.urls.gen + '/v2/games/legends.png'} />,
        'oras': <img className='w-full h-12' src={config.urls.gen + '/v2/games/oras.png'} />,
      }[game || '']}
    </div>
  );
}

export default Compatibility;
