import logo from '../images/logo.png';
import logoBg from '../images/logo-bg.png';
import Search from './Search.js';
import NavButton from './NavButton.js';
import { Large } from '../responsive.js';
import { useMediaQuery } from 'react-responsive';

import { Link } from 'react-router-dom';

function Header() {
  const probablyWrapping = useMediaQuery({ maxWidth: 600 });

  return (
    <div
      className="sticky top-0 bg-white flex justify-between shadow-2xl items-center px-2 bg-no-repeat bg-scale bg-center"
      style={{
        zIndex: 2,
        backgroundImage: probablyWrapping ? `url(${logoBg})` : 'none',
      }}
    >
      <div className="flex flex-wrap items-center gap-2">
        {!probablyWrapping && (
          <Link className="h-16 w-max" to="/">
            <img className="h-16 w-auto p-2" src={logo} alt="Pokezon logo" />
          </Link>
        )}

        <Search className="sm:mx-auto w-min mb-1 mt-3 sm:mt-0 sm:mb-0" />

        <div className="flex w-max sm:w-min justify-between gap-6 mt-1 sm:mt-0 sm:gap-3 mb-2 sm:mb-0 sm:pb-1 mr-auto">
          <NavButton target="/pokemon" title="Pokemon" />
          <NavButton target="/items" title="Items" />
          <NavButton target="/bundles" title="Bundles" />
        </div>
      </div>

      <div className="p-1 sm:p-4 flex flex-wrap-reverse gap-2 justify-end items-center">
        <Link
          className="font-bold pr-0  block text-right text-base whitespace-nowrap"
          to="/orders"
        >
          <Large>My</Large> Orders
        </Link>
      </div>
    </div>
  );
}

export default Header;
