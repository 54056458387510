import { capitalize, without } from 'util';

import { useState, useEffect } from 'react';

function MultiSelect({options, selected, setSelected, formatter, sort, title}) {

  return (
    <div className='grid items-center gap-x-3 row-span-1' style={{gridTemplateColumns: '10px auto'}}>
      {(sort ? options.sort() : options).map(option => (
        <>
          <input id={`checkbox-${title}-${option}`} className='w-3' type='checkbox' checked={selected.includes(option)} onChange={() => {
            if(selected.includes(option)) setSelected(without(option, selected));
            else setSelected(selected.concat(option));
          }} />

          <label htmlFor={`checkbox-${title}-${option}`} className='whitespace-nowrap select-none'>{formatter ? formatter(option) : capitalize(option)}</label>
        </>
      ))}
    </div>
  );
}

export default MultiSelect;
